import defapower from '../../assets/images/defapower.png'
import DEFACharger from '../DEFACharger/DEFACharger'
import MobileCoverage from '../MobileCoverage/MobileCoverage';
import Button from '../Button/Button';
import { useLocation, useParams } from 'react-router-dom';
import picture from '../../assets/images/green-bars.svg'
import { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import TermsAndCond from '../TermsAndCond/TermsAndCond';
import CurrencyOption from '../CurrencyOption/CurrencyOption';
import AppViewNav from '../AppViewNav/AppViewNav';

const BACKEND_HOST = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : "http://localhost:8080";
let HOST; 
if (window.location.port.length > 0) {
    HOST = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
} else {
    HOST = window.location.protocol + "//" + window.location.hostname
}


const Coverage = () => {
    let { serialNumber } = useParams();
    let { pathname } = useLocation();
    
    const [valid, setValid] = useState(false);
    const [lte, setLte] = useState(false);
    const [loading, setLoading] = useState(true);
    const [prices, setPrices] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const urlParams = new URLSearchParams(window.location.search);
    
    useEffect(() => {
        fetch(`${BACKEND_HOST}/power/${serialNumber}`)
        .then(response => {
            if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`)
            }
            setValid(true)
            return response.json()
        })
        .then(x => fetch(`${BACKEND_HOST}/power/${serialNumber}/lte-enabled`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`This is an HTTP error: The status is ${response.status}`)
                }
                return response.json()
            })
            .then(payload => {if (payload.enabled) setLte(payload)})
            .catch(x => console.error("Error Internal: ", x.message))
        )
        .catch(x => console.log("Error External: ", x.message))
        .finally(x => setLoading(false));
    }, [serialNumber]);
    
    useEffect(() => {
        fetch(`${BACKEND_HOST}/payment/power/prices`)
        .then(res => res.json())
        .then(val => {
            setPrices(val.prices); 
            const defaultCurrency = val.prices.find(x => x.defaultPrice)
            if(defaultCurrency) {
                setSelectedCurrency(defaultCurrency);
            }
        })
        .catch(x => console.error("Error getting prices: ", x.message));
    },[]);

    const createCheckoutSessionPayload = () => {
        return {
            method: "POST",
            body: 
                JSON.stringify({
                    successUrl: createSuccessUrl(),
                    cancelUrl: createCancelUrl(),
                    priceId: selectedCurrency.priceId,
                    currency: selectedCurrency.currency
                }),
                headers: {
                    "Content-Type": "application/json",
                }
        }
    }
    const displayCurrency = () => {
        return `${selectedCurrency.price} ${selectedCurrency.currency.toUpperCase()}`
    }
    const createSuccessUrl = () => {
        if (urlParams.size > 0) {
            return `${HOST}/power/${serialNumber}/success?${urlParams.toString()}`
        }
        return `${HOST}/power/${serialNumber}/success`
        
    };

    const createCancelUrl = () => {
        if (urlParams.size > 0) {
            return `${HOST}/power/${serialNumber}/cancel?${urlParams.toString()}`
        }
        return `${HOST}/power/${serialNumber}/cancel`
    };

    const isAppView = () => {
        const params = Object.fromEntries(urlParams.entries());
        return params.hasOwnProperty('app');
    }
    const purchase = () => {
        fetch(`${BACKEND_HOST}/payment/power/${serialNumber}/create-checkout-session`, createCheckoutSessionPayload())
        .then(response => {
            if(!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`)
            }
            return response.json()
        })
        .then(data => redirect(data.url))
        .catch(x => console.error("ERROR CREATING CHECKOUT SESSION"))
    }

    if (loading) {
        return (
            <Spinner />
        )
    }

    if(!valid) {
        return (
        <div>
            <MobileCoverage 
                headerText={"Serial number does not exist."} 
            />
            <Button displayText={"Back"} buttonAction={() => handleRoute()} />    
        </div>
        )
    }

    if (pathname.includes("success")) {
        return (
            <div style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <DEFACharger csId={serialNumber} imageLogo={defapower}/>
                <MobileCoverage 
                    headerText={"Mobile network activated on DEFA Power"} 
                    pic={picture}
                />
                {isAppView() ? <></> : 
                    < AppViewNav 
                        showNetworkActivation={true} 
                        showNavigation={true} 
                        redirect={redirect}
                    />   
                }
            </div> 
        )
    }

    if (pathname.includes("cancel")) {
        return (
            <div>
                <DEFACharger csId={serialNumber} imageLogo={defapower}/>
                <MobileCoverage 
                    headerText={"Purchase cancelled"} 
                    pic={picture}
                />
                {isAppView() ? <></> : 
                    < AppViewNav 
                        showNetworkActivation={false} 
                        showNavigation={true} 
                        redirect={redirect}
                    />                
                }
            </div>
        )
    }
    
    return (
        <div>
            <DEFACharger csId={serialNumber} imageLogo={defapower}/>
            {lte ? 
                <>
                    <MobileCoverage 
                        headerText={"Mobile Network already activated on this DEFA Power"} 
                        bodyText={[`Valid until ${new Date(lte.endTime).toLocaleDateString()}`]}
                        pic={picture} 
                    />
                    {isAppView() ? <></> : 
                        < AppViewNav 
                            showNetworkActivation={false} 
                            showNavigation={true} 
                            redirect={redirect}
                        />                    
                    }
                </> 
                :   
                <>
                    <MobileCoverage 
                        headerText={"Stay connected, anywhere."} 
                        bodyText={["Activate internet access immediately on this DEFA Power without any additional setup.", "Note! Using DEFA Power with DEFA Balancer requires Wi-Fi or Ethernet connection"]}
                        pic={picture}
                    />
                    {prices.length >= 2 ? <CurrencyOption list={prices} callback={setSelectedCurrency} /> : <></>}
                    {selectedCurrency !== "" ? <Button displayText={`Buy now for ${displayCurrency()}`} buttonAction={() => purchase()} /> : <></>}
                    <TermsAndCond text={"By continuing to check out, you accept "} link={"https://www.defa.com/terms-mobile-services"} />
                </> 
            }
        </div>
    );
}

const handleRoute = () => {
    window.location.href = "/";
}

const redirect = (url) => {
    window.location.href = url;
}

export default Coverage;