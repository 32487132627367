import UserNavInfo from '../UserNavInfo/UserNavInfo';
import Button from '../Button/Button';

const AppViewNav = ({showNetworkActivation, showNavigation, redirect}) => {
return (
    <div>
        <Button displayText={"Back to DEFA.com"} buttonAction={() => redirect("https://defa.com")} /> 
        < UserNavInfo networkActivation={showNetworkActivation} navigation={showNavigation} />
    </div>   
    )
}

export default AppViewNav;