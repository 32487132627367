import './UserNavInfo.css'

const UserNavInfo = ({networkActivation, navigation}) => {
    return (
        <div className='usernavinfo-text'>
            {networkActivation ? <p className='usernavinfo-text-upper'>It can take a couple of minutes until the mobile network is available in the app.</p> : <p></p>}
            {navigation ? <p className='usernavinfo-text-lower'>You can close this page now.</p> : <></>}    
        </div>
    )
}

export default UserNavInfo;